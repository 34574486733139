export const configUrl = {
  development: {
    weburl: `https://6kfsd43nzb.execute-api.ap-southeast-2.amazonaws.com/dev/getDBRec`,
    serviceTimeout: 30,
  },
  production: {
    weburl: `https://6kfsd43nzb.execute-api.ap-southeast-2.amazonaws.com/dev/getDBRec`,
    serviceTimeout: 60,
  },
};

