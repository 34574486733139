import {
  LOAD_MONITOR_IN_PROGRESS,
  LOAD_MONITOR_SUCCESS,
  LOAD_MONITOR_FAILURE,
  LOAD_MERCHANT_TEMP,
  LOAD_DYNAMIC_TEMPLATE,
} from "./actions";

const initialState = {
  isLoading: false,
  data: [],
  isTempLoading: false,
  datatemp:'',
  template:[],
};

export const monitorsRe = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MONITOR_SUCCESS: {
      const { monitors } = payload;
      return {
        ...state,
          isLoading: false,
          isTempLoading: false,
          data: monitors,
      };
    }
    case LOAD_MONITOR_IN_PROGRESS:
      return {
        ...state,
          isLoading: true,
          isTempLoading:false,
      };
    case LOAD_MONITOR_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case LOAD_MERCHANT_TEMP:
      const { merchantId } = payload;
      return {
        ...state,
        isLoading: false,
        isTempLoading:true,
        data: state.data,
        datatemp: merchantId,
      };
    case LOAD_DYNAMIC_TEMPLATE:
      const { dtemplate } = payload;
      return {
        ...state,
        isLoading: false,
        isTempLoading: true,
        data: state.data,
        datatemp: state.datatemp,
        template: dtemplate,
      };
    default:
      return state;
  }
};
