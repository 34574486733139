/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:54318d77-3d5a-4eb3-9ce6-a540cecbec54",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_y8Qgkh2xS",
    "aws_user_pools_web_client_id": "7oehalf0gljlfo4biprccj3vk9",
    "oauth": {}
};


export default awsmobile;
