import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { getMenchantTemp } from "./selectors";
import { loadMerchantTemp } from "./actions";
import PropTypes from "prop-types";
const MonitorDetailContainer = styled.div`
    background: #fff;
    border-radius: 8px;
    font-size:14px;
    padding: 10px;
    position: relative;
    box-shadow: 0 4px 8px grey;
    height:auto;
    margin-bottom:15px;
    background-color: ${(props) =>
        props.bg === "dark" ? "light" : "lightgray"};
`;
const MonitorDetailContainerWithWarning = styled(MonitorDetailContainer)`
    border-bottom: ${ (props) => 
    new Date(props.createdAt) <= new Date(Date.now() - 86400000 * 2)
        ? "null"
        : "2px solid red"};
`;
const Button = styled.button`
  font-size: 14px;
  margin-right:6px;
  border: none;
  display: inline-block;
  color: #ffffff;
  padding: 0.4rem;
  outline: none;
  cursor: pointer;
`;

const TemplateButton = styled(Button)`
  background-color: ${(props) =>
    new Date(props.createdAt) <= new Date(Date.now() - 86400000 * 2)
      ? "red"
      : "#ff9900"};
   margin-left: 8px;
`;
const Ampm = ({ ap }) => {
  let strFlg = "pm";
  if (ap < 12) strFlg = "am";
  return strFlg;
};



const MonitorDetail = (props) => {
  const { monitorUrl, monitorData, orderDate, orderTime, merchantId,openMerchantTemp } = props;
  const Container = merchantId ? MonitorDetailContainer : MonitorDetailContainerWithWarning;
    return (
      <Container bg="dark">
        <Fragment>
          <TemplateButton
            createdAt={orderDate}
            onClick={() => {
              openMerchantTemp(merchantId);
            }}
          >
            <span>{merchantId} &nbsp;</span>
          </TemplateButton>
          <span>{monitorUrl}</span>
          <hr />
          <p>
            Last Widget Call at:&nbsp;
            {new Date(orderDate).toLocaleDateString()} {orderTime}<Ampm ap= {orderTime.split(":", 1)}/>
           </p>

          <p> API : {JSON.stringify(monitorData, null, 2)} </p>
        </Fragment>
      </Container>
    );
}

const mapStateToProps = (state) => ({
  merchantTemp: getMenchantTemp(state),
});
const mapDispatchToProps = (dispatch) => ({
  openMerchantTemp: (merchantId) => dispatch(loadMerchantTemp(merchantId)),
});

MonitorDetail.propTypes = {
  monitorUrl: PropTypes.string,
  monitorData: PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
  orderDate: PropTypes.string,
  orderTime: PropTypes.string,
  merchantId: PropTypes.string,
};

MonitorDetail.defaultProps = {
  monitorUrl: "https://docs.shophumm.com.au/",
};


export default connect(mapStateToProps, mapDispatchToProps)(MonitorDetail);