import {
    loadMonitorInProgress,
    loadMonitorSuccess,
    loadMonitorFailure,
    loadDynamicTemplate
} from './actions';
import { configUrl } from './config-widget'
export const loadMonitor = () => async (dispatch, getState) => {
    try {
        dispatch(loadMonitorInProgress());
        const response = await fetch(
            configUrl.production.weburl,
            {
                method: "get",
                redirect: "follow",
            }
        );
        let monitors = await response.json();
        dispatch(loadMonitorSuccess(monitors));
    } catch (e) {
        dispatch(loadMonitorFailure());
        dispatch(displayAlert(e));
    }
}
export const addMonitorRequest = (inputMerchant, selectedDate) => async (dispatch) => {
    let url = '';
    if (inputMerchant&& selectedDate) {
        url = `${configUrl.production.weburl}?merchantNumber=${inputMerchant}&WidgetsStart=${selectedDate}`;
      
    }
    if (!inputMerchant && selectedDate) {
        url = `${configUrl.production.weburl}?WidgetsStart=${selectedDate}`;
    }

    if (inputMerchant && !selectedDate) {
        url = `${configUrl.production.weburl}?merchantNumber=${inputMerchant}`;
    }

      if (!inputMerchant && !selectedDate) {
        url = configUrl.production.weburl;
      }
    try {
        dispatch(loadMonitorInProgress());
        const response = await fetch(
            url
        );
        const monitors = await response.json();
        dispatch(loadMonitorSuccess(monitors));
    } catch (e) {
        dispatch(loadMonitorFailure());
        dispatch(displayAlert(e));
    }
};

export const loadTemplate = (merchantId) => async (dispatch) => {
    let url = "";
    if (merchantId) {
         merchantId = merchantId+"_template"
        url = `${configUrl.production.weburl}?merchantNumber=${merchantId}`;
    }
  try {
    const response = await fetch(url, {
      method: "get",
      redirect: "follow",
    });
    let dtemplate = await response.json();
    dispatch(loadDynamicTemplate(dtemplate));
  } catch (e) {
    dispatch(displayAlert(e));
  }
};

export const displayAlert = text => () => {
    alert(text);
};