import React from 'react';
import styled from 'styled-components';
import { withAuthenticator} from "@aws-amplify/ui-react"
import MonitorList from './monitor/MonitorList';
import './App.css';



const AppContainer = styled.div`
    margin: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    color: #222222;
    width: 100vw;
    height: 100vh;
`;

const App = () => {
    return (
       <AppContainer>
          <MonitorList />
      </AppContainer>
    );
} 

export default withAuthenticator(App);