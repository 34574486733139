import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MonitorDetail from './MonitorDetail';
import Popwin  from '../template/pop';
import {
  getMonitorsLoading,
  getMenchantTempLoading,
  getMenchantTemp,
  getSorteMonitorsRe,
} from "./selectors";
import { loadMonitor } from "./thunks";
import MonitorInput from './MonitorInput';

const MonitorWrapper = styled.div`
    max-width: 1500px;
    margin: auto;
`;
const MonitorList = ({
  completedMonitors = [],
  isLoading,
  isTempLoading,
  startLoadingMonitors,
  getMenchantTemp,
  element
}) => {
  useEffect(() => {
     startLoadingMonitors();
     const timer = setInterval(() => {
      startLoadingMonitors();
    }, 300000);
    return () => clearInterval(timer);
  }, [startLoadingMonitors]);

   const paneDidMount = (node) => {
     if (node) {
       node.addEventListener("scroll", () => {});
     }
   };
  const loadingMessage = <div style={{ 'color': 'golden' }}>Loading Monitor Information...</div>;
  const style = {
    overflowY: 'scroll',
    height: '830px',
    padding: '6px',
    borderRadius: '8px',
  };
  const nav = {
    width: '35%',
    float:'left'
  }
  const content =(
       <MonitorWrapper>
           <div style={ nav }>
              <MonitorInput />
           </div>
       <div ref={paneDidMount} style={style}>
        {completedMonitors.map((monitor,index) => {
               
              const clickHandle = getMenchantTemp === monitor.merchantId;
               
              const detailContent = (
                <MonitorDetail
                  key = {index}
                  monitorUrl= {monitor.cliUrl}
                  monitorData={monitor.data}
                  orderDate={monitor.orderDate}
                  orderTime={monitor.orderTime}
                  merchantId={monitor.merchantId}
                />
            
             );
             return clickHandle && isTempLoading ? (
               <Popwin key={index} merchantId={monitor.merchantId} />
             ) : (
               detailContent
             );
          })}
            </div>
       </MonitorWrapper>
  );

  return isLoading ? loadingMessage : content;
};


const mapStateToProps = (state) => ({
  isLoading: getMonitorsLoading(state),
  completedMonitors: getSorteMonitorsRe(state),
  isTempLoading: getMenchantTempLoading(state),
  getMenchantTemp: getMenchantTemp(state),
});

const mapDispatchToProps = dispatch => ({
    startLoadingMonitors: () => dispatch(loadMonitor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorList);