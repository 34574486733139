import styled from "styled-components";
import { loadTemplate } from "../monitor/thunks";
import { connect } from "react-redux";
import { getCompleteTemplate } from "../monitor/selectors";

const TemplateContainer = styled.div`
  font-size:13px;
  border-radius: 8px;
  margin-top: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 0 4px 8px grey;
  height: 200px;
  background-color: ${(props) => (props.bg === "dark" ? "light" : "lightgray")};
`;
const Popwin = ({ merchantId, startLoadingTemplate, completeTemplate }) => {
  return (
    <TemplateContainer>
       No: { merchantId}
      <button style= {{float:"right"}} > Confirm</button>
      <button style={{ float: "right" }} onClick={() => { startLoadingTemplate(merchantId) }}> Showing </button>
      <hr/>
    </TemplateContainer>);
};
const mapStateToProps = (state) => ({
   completeTemplate: getCompleteTemplate(state),
});
const mapDispatchToProps = (dispatch) => ({
  startLoadingTemplate: (merchantId) => dispatch(loadTemplate(merchantId))});
export default connect(mapStateToProps, mapDispatchToProps)(Popwin);
