export const LOAD_MONITOR_IN_PROGRESS = "LOAD_MONITOR_IN_PROGRESS";
export const loadMonitorInProgress = () => ({
    type: LOAD_MONITOR_IN_PROGRESS,
});

export const LOAD_MONITOR_SUCCESS = "LOAD_MONITOR_SUCCESS";
export const loadMonitorSuccess = (monitors) => ({
    type: LOAD_MONITOR_SUCCESS,
    payload: { monitors },
});

export const LOAD_MONITOR_FAILURE = 'LOAD_MONITOR_FAILURE';
export const loadMonitorFailure = () => ({
    type: LOAD_MONITOR_FAILURE,
});

export const LOAD_MERCHANT_TEMP = 'LOAD_MERCHANT_TEMP';

export const loadMerchantTemp = (merchantId) => ({
  type: LOAD_MERCHANT_TEMP,
  payload: { merchantId },
});

export const LOAD_DYNAMIC_TEMPLATE = 'LOAD_DYNAMIC_TEMPLATE';

export const loadDynamicTemplate = (dtemplate) => ({
    type: LOAD_DYNAMIC_TEMPLATE,
    payload: {dtemplate},
});