import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { addMonitorRequest } from "./thunks";
import DateFnsUtils from "@date-io/date-fns";
import logo from '../img/logo.svg';
import Clock from '../clock/clock'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

const FormContainer = styled.div`
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px grey;
`;

const MonitorInputMerchant = styled.input`
  text-align: left;
  font-size: 14px;
  padding: 4px;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 8px;
  width: 70%;
  outline: none;
`;

const MonitorButton = styled.button`
  height: 50px;
  margin-bottom: 6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  padding: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  background-color: #ff9900;
`;

const NaviPanel = {
  padding: '6px'
}

const NaviFloat = {
  float: 'left',
  width: '20%'
}



const MonitorInput = ({ onMonitorPressed}) => {
  const [inputMerchant, setInputMerchant] = useState("");

  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format("yyyy/MM/DD"));
  };

  return (
    <div style={NaviPanel}>
      <FormContainer>
        <div style={NaviFloat}>
          <img src={logo} alt="logo" ></img>
          <Clock
            format={"h:mm:ssa"}
            style={{ fontSize: "0.8em" }}
            ticking={true}
          />
        </div>
        <MonitorInputMerchant
          type="text"
          placeholder="Type merchant number here"
          value={inputMerchant}
          onChange={(e) => setInputMerchant(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
               onMonitorPressed(inputMerchant, selectedDate);
                }
          }}
          />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Monitor Date picker "
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
         
        <MonitorButton
          onClick={() => {
            onMonitorPressed(inputMerchant, selectedDate);
          }}
        >
          CHECK BPI
        </MonitorButton>
        <AmplifySignOut />
      </FormContainer>
      </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
    onMonitorPressed: (inputMerchant, selectedDate) =>
    dispatch(addMonitorRequest(inputMerchant, selectedDate)),
});

export default connect(null, mapDispatchToProps)(MonitorInput);
