
import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";

export const getMonitors = state => state.monitorsRe.data;
export const getMonitorsLoading = state => state.monitorsRe.isLoading;

export const getMenchantTemp = (state) => state.monitorsRe.datatemp;
export const getMenchantTempLoading = (state) => state.monitorsRe.isTempLoading;

export const getCompleteTemplate = (state) => state.monitorsRe.template;


export const getSorteMonitorsRe = createSelector(
   getMonitors,
   (collections) => {
      return orderBy(collections,['merchantId'],['asc']);
    }
)
